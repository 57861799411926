import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import * as React from 'react';

type Props = {
  progress: number;
}

const Progress = styled.progress`
  width: 100%;
  margin: 2rem;

  height: 20px;
  position: relative;
  
  ::-webkit-progress-value { 
    background: ${({ theme }) => theme.colors.secondary}; 
  }
  
  ::-moz-progress-bar { 
    background: ${({ theme }) => theme.colors.secondary}; 
  }
  
  transition: 0.3s;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      transparent 25%,
      rgba(255,255,255,1) 15%,
      rgba(255,255,255,1) 35%,
      transparent 30%,
      transparent 70%
    );
    animation: shift 2s linear infinite;
    background-size: 60px 100%;
  }
  
  @keyframes shift {
    to {
      background-position: 60px 100%;
    }
  }
`;
export const ProgressBar: React.FC<Props> = ({
                                               progress,
                                             }) => (
  <>
    <Progress
      value={40}
      max={100}
    >
      {progress}%
    </Progress>
  </>
);
